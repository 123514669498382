import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import '../styles/global.css';

import DirtLayout from '../components/DirtLayout';
import Seo from '../components/Seo';
import Cta from '../components/Cta';
import video from '../images/Hunter-Environmental-Commerical-Drainage-Feature-1.mp4';
import DirtLinks from '../components/DirtLinks';

export default function CommercialDrainage({ data }) {
   return (
      <DirtLayout>
         <Seo title='Commercial - Municipal Drainage' />
         <CommercialHeader />
         <DirtLinks />
         <section className='text-gray-600 body-font'>
            <div className='px-5 py-8 md:py-24 mx-auto flex flex-wrap'>
               <div className='max-w-7xl mx-auto'>
                  <div className='flex flex-wrap w-full h-64 md:h-96 relative mb-4'>
                     <video muted autoPlay loop className='w-full object-cover h-full object-center block  absolute inset-0'>
                        <source src={video} alt='Hunter Environmental excavator working on draining' type='video/mp4' />
                     </video>
                  </div>
                  <div className='flex flex-wrap -mx-2'>
                     <div className='px-2 w-1/2'>
                        <div className='flex flex-wrap w-full relative h-32 md:h-64'>
                           <StaticImage
                              alt='Hunter Environmental Excavator installing a septic'
                              src='../images/Hunter-Environmental-Commerical-Drainage-Feature-3.jpg'
                              className='w-full object-cover h-full object-center block  absolute inset-0'
                           />
                        </div>
                     </div>
                     <div className='px-2 w-1/2'>
                        <div className='flex flex-wrap w-full relative h-32 md:h-64'>
                           <StaticImage
                              alt='Hunter Environmental worker working on drainage'
                              src='../images/Hunter-Environmental-Commerical-Drainage-Feature-2.jpg'
                              className='w-full object-cover h-full object-center block  absolute inset-0'
                           />
                        </div>
                     </div>
                  </div>

                  <div className='mx-auto mt-8 text-base  max-w-prose'>
                     <div>
                        <h2 className='text-base text-dirt text-center font-semibold tracking-wide uppercase'>Site Services</h2>
                        <h3 className='mt-2 text-3xl leading-8 text-center font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
                           {data.dirtservice.name}
                        </h3>
                     </div>
                     <div className='text-base prose max-w-prose mx-auto lg:max-w-none mt-8'>
                        <p className='text-lg text-gray-500'>{data.dirtservice.desc}</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <Cta bgColor='bg-dirt' bgHover='hover:bg-dirt-dark' textColor='text-dirt' />
      </DirtLayout>
   );
}

const CommercialHeader = () => {
   return (
      <div className='grid'>
         <StaticImage
            style={{ gridArea: '1/1' }}
            layout='fullWidth'
            alt='Hunter Environmental working in a trench'
            src='../images/Hunter-Environmental-Commerical-Drainage-Header.jpg'
            formats={['auto', 'webp', 'avif']}
            className='h-128'
         />
         <div aria-hidden='true' style={{ gridArea: '1/1' }} className='bg-gradient-to-b from-black opacity-80 relative inset-0 z-10' />
         <div className='grid relative items-center w-full z-20' style={{ gridArea: '1/1' }}>
            <div className='w-full h-fit'>
               <div className='flex flex-col justify-center items-center md:items-start max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:px-0'>
                  <div className='text-4xl lg:text-6xl text-center md:text-left md:pl-2 my-6 md:border-l-8 md:border-dirt font-bold text-white'>
                     <motion.h1
                        initial={{ opacity: 0, y: -15 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                        className='mb-4 text-center md:text-left'
                     >
                        Commercial
                     </motion.h1>
                     <motion.h1 initial={{ opacity: 0, y: -15 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
                        Drainage
                     </motion.h1>
                  </div>

                  <AnchorLink
                     to='/dirt#contact'
                     style={{ fontFamily: 'Muli' }}
                     className='w-max md:ml-4 shadow-lg mt-8 bg-dirt hover:bg-dirt-light  text-xs md:text-base rounded-md text-black px-12 py-2'
                  >
                     Contact Us
                  </AnchorLink>
               </div>
            </div>
         </div>
      </div>
   );
};

export const pageQuery = graphql`
   query {
      dirtservice(name: { in: "Commercial Drainage" }) {
         id
         name
         desc
         brief
      }
   }
`;
